import React from 'react'
import tw from 'tailwind.macro'
import styled from 'styled-components'
import theme from '../../config/theme'
import "../components/layout.css"

import SEO from '../components/seo'
import Logo from '../components/logo'
import { SiteTitle, Title } from '../components/titles'
import SignUp from '../components/signup-form'

const Wrapper = styled.div`
  ${tw`m-2 flex`};
  min-height: 100vh;
`

const Box = styled.div`
  ${tw`p-5 md:p-16 flex flex-wrap items-center flex-col`}
  border: .1rem solid ${theme.colors.primary};
  max-width: 40rem;
  width: 100%;
  margin: auto;
  border-radius: 1rem;
  text-align: center;
  a svg {
    margin-bottom: 2rem;
  }
`

const Form = styled.div`
  ${tw`px-10 py-5 w-full`}
`

const Registro = props => (
  <>
    <SEO title="Registro" description="Registro Jornada10 - Empieza ahora" />
    <Wrapper>
      <Box>
        <Logo/>
        <SiteTitle>Jornada</SiteTitle>
        <Title>El teu espai</Title>
        <p>Comença amb el teu pla gratuïtament, afegeix treballadors, augmenta progressivament i paga a mesura que generis registres.</p>
        <Form>
          <SignUp location={props.location}/>
        </Form>
        {/* <h4>Rebràs per correu les instruccions per entrar a la nostra plataforma.</h4> */}
      </Box>
    </Wrapper>
  </>
)

export default Registro